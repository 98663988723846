import React, { useEffect, useState, useCallback, useRef, Fragment } from 'react'
import { IconButton, FlexColumn, BaseCheckBox } from 'styles/components'
import { Options, OptionsWrapper, OptionsBase } from '../styles'
import Menu from 'Menu'
import _ from 'lodash'
import { inject, observer } from 'mobx-react'
import { reaction } from 'mobx'
import { withNamespaces } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import Link from './link'
import { isMobile, isAndroid } from 'react-device-detect'
import SortButton from './sortButton'

const ButtonMenu = observer(({ rootStore, t }) => {
  const { selectedTenant } = rootStore.authStore

  reaction(
    () => rootStore.areaStore.areas.slice(),
    () => loadMenu()
  )

  reaction(
    () => rootStore.workStationStore.workStations.slice(),
    () => loadMenu()
  )

  reaction(
    () => rootStore.authStore.roles.slice(),
    () => loadMenu()
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadMenu(), [])

  const loadMenu = useCallback(() => {
    madeWorkStationSubMenus()
    madeMenu()
  }, [madeWorkStationSubMenus, madeMenu])

  const [menu, setMenu] = useState(
    localStorage.getItem(selectedTenant + '-menuOptions')
      ? JSON.parse(localStorage.getItem(selectedTenant + '-menuOptions'))
      : []
  )
  const { areasMenu } = rootStore.areaStore
  const [ordering, setOrdering] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef(null)
  const [showingMenu, setShowingMenu] = useState({ menu: undefined, top: 0, left: 0 })

  const madeWorkStationSubMenus = useCallback(() => {
    const { areas } = rootStore.areaStore
    const { workStations } = rootStore.workStationStore

    const workStationsByAreaId = _.chain(workStations)
      .orderBy(['shortName'])
      .groupBy('areaId')
      .value()

    let haveUserOrdering = areasMenu.length !== 0
    let lastUserOrdering = !haveUserOrdering
      ? 0
      : areasMenu.sort((a, b) => b.order - a.order)[0].order

    const menu = _.chain(areas)
      .orderBy(['description'])
      .filter((area) => area.id !== -1)
      .map((area, i) => {
        const workStationsGroup = workStationsByAreaId[area.id]
        if (workStationsGroup) {
          const areaOrdering = areasMenu.find((aM) => aM.id === area.id)

          const currentOrder =
            !haveUserOrdering || !areaOrdering ? ++lastUserOrdering : areaOrdering.order

          let lastUserWorkStationOrdering = !areaOrdering
            ? 0
            : areaOrdering.subItems.sort((a, b) => b.order - a.order)[0].order

          return {
            name: area.description,
            order: currentOrder,
            id: area.id,
            subItems: workStationsGroup.map((workStation, o) => {
              const workStationOrdering = areaOrdering?.subItems?.find(
                (w) => w.subPath === `${workStation.id}`
              )

              const currentWorkStationOrder =
                !haveUserOrdering || !workStationOrdering
                  ? ++lastUserWorkStationOrdering
                  : workStationOrdering.order

              return {
                subName: workStation.shortName,
                order: currentWorkStationOrder,
                subPath: workStation.id.toString(),
              }
            }),
          }
        }

        return null
      })
      .filter((menuItem) => menuItem !== null)
      .value()

    rootStore.areaStore.updateAreaOrdering(menu)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootStore])

  const filterRoles = useCallback(
    (menu, outerAllowedRoles) => {
      const { roles } = rootStore.authStore

      if (menu.subMenu?.length > 0) {
        menu.subMenu = menu.subMenu.filter((subMenuItem) =>
          filterRoles(subMenuItem, menu.allowedRoles)
        )
        return menu.subMenu.length > 0
      } else {
        if (!menu.allowedRoles) {
          if (!outerAllowedRoles) return true
          return roles.some((r) => outerAllowedRoles.includes(r))
        }

        return roles.some((r) => menu.allowedRoles.includes(r))
      }
    },
    [rootStore]
  )

  const madeMenu = useCallback(() => {
    let updatedMenu = JSON.parse(JSON.stringify(Menu))
    updatedMenu = updatedMenu.filter((menuItem) => filterRoles(menuItem))
    if (menu.length > 0) {
      updatedMenu = updatedMenu.map((u) => {
        const menuItem = menu.find((m) => m.path === u.path)
        if (menuItem) u.order = menuItem.order
        return u
      })
      updatedMenu = updatedMenu.sort((a, b) => a.order - b.order)
    }
    setMenu(updatedMenu)
  }, [filterRoles, menu])

  const handleClick = useCallback(
    (event) => {
      if (!menuOpen || ordering) return
      if (isMobile || isAndroid) {
        if (
          (menuRef && !menuRef.current.contains(event.target)) ||
          event.target.classList.contains('removable-li')
        ) {
          setTimeout(() => {
            setMenuOpen(false)
            setShowingMenu((prev) => ({ ...prev, menu: undefined }))
          }, 10)
        }
        return
      }
      setTimeout(() => {
        setMenuOpen(false)
        setShowingMenu((prev) => ({ ...prev, menu: undefined }))
      }, 10)
    },
    [setMenuOpen, setShowingMenu, menuOpen, ordering]
  )

  useEffect(() => {
    document.addEventListener('click', handleClick, true)
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [handleClick])

  useEffect(() => {
    localStorage.setItem(selectedTenant + '-menuOptions', JSON.stringify(menu))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])

  const showSubMenu = (e) => {
    const pos = e.target.getBoundingClientRect()

    setShowingMenu({
      menu: e.target.getAttribute('name'),
      top: pos.top - pos.height * 2,
      left: pos.width,
    })
  }

  const sortMenu = (currentIndex, newIndex) => {
    const updatedMenu = menu
      .map((menuMap) => {
        if (menuMap.order === newIndex) menuMap.order = currentIndex
        else if (menuMap.order === currentIndex) menuMap.order = newIndex
        return menuMap
      })
      .sort((a, b) => a.order - b.order)

    setMenu(updatedMenu)
  }

  const sortArea = (currentIndex, newIndex) => {
    const updatedAreas = areasMenu
      .map((areaMap) => {
        if (areaMap.order === newIndex) areaMap.order = currentIndex
        else if (areaMap.order === currentIndex) areaMap.order = newIndex
        return areaMap
      })
      .sort((a, b) => a.order - b.order)

    rootStore.areaStore.updateAreaOrdering(updatedAreas)
  }

  const sortWorkStation = (areaId, currentIndex, newIndex) => {
    let updatedArea = areasMenu.find((ar) => ar.id === areaId)
    updatedArea.subItems = updatedArea.subItems
      .map((menuMap) => {
        if (menuMap.order === newIndex) menuMap.order = currentIndex
        else if (menuMap.order === currentIndex) menuMap.order = newIndex
        return menuMap
      })
      .sort((a, b) => a.order - b.order)
    const updatedAreas = areasMenu.map((areaMenu) =>
      areaMenu.id === updatedArea.id ? updatedArea : areaMenu
    )
    rootStore.areaStore.updateAreaOrdering(updatedAreas)
  }

  return (
    <Options>
      <IconButton className="fas fa-bars" light pointer onClick={() => setMenuOpen(true)} />
      <OptionsWrapper
        marginLeft
        isOpen={menuOpen}
        ref={menuRef}
        isMobile={isMobile || isAndroid}
        direction={'top left'}
        style={{
          zIndex: 9999,
        }}
      >
        <Link isActive={true} to={''}>
          <OptionsBase
            name={'Início'}
            isActive={showingMenu.menu === 'home'}
            hasSubMenu={false}
            className={'removable-li'}
            justify={ordering && 'space-between'}
          >
            <div>
              <IconButton className={'fas fa-home'} pointer marginRight={10} />
              {t('pageTitle.home')}
            </div>
          </OptionsBase>
        </Link>
        {menu
          .filter((menu) => !menu.heading)
          .sort((a, b) => a.order - b.order)
          .map((menuMap, i) => {
            const hasSubMenu = menuMap.subMenu?.length > 0 || menuMap.hasActivities
            return (
              <Fragment key={menuMap.name}>
                <Link key={menuMap.name} isActive={!ordering && !hasSubMenu} to={menuMap.path}>
                  <OptionsBase
                    name={menuMap.name}
                    isActive={showingMenu.menu === menuMap.name}
                    hasSubMenu={hasSubMenu && !ordering}
                    onMouseEnter={showSubMenu}
                    className={!ordering && !hasSubMenu ? 'removable-li' : ''}
                    justify={ordering && 'space-between'}
                    onTouchStart={() => setShowingMenu((prev) => ({ ...prev, menu: menuMap.name }))}
                  >
                    <div>
                      <IconButton className={menuMap.icon} pointer marginRight={10} />
                      {t(menuMap.translate)}
                    </div>
                    {ordering && (
                      <SortButton
                        sortUp={() => sortMenu(menuMap.order, menuMap.order--)}
                        sortDown={() => sortMenu(menuMap.order, menuMap.order++)}
                        hiddenUp={i === 0}
                        hiddenDown={i === menu.length - 1}
                      />
                    )}
                  </OptionsBase>
                </Link>
                {hasSubMenu && (
                  <OptionsWrapper
                    isOpen={showingMenu.menu === menuMap.name}
                    top={showingMenu.top}
                    isMobile={isMobile || isAndroid}
                    autoWidth
                    left={showingMenu.left}
                    isSubMenu
                    direction={'top left'}
                    style={{
                      width: !isMobile && 'max-content',
                    }}
                  >
                    {menuMap.subMenu
                      .concat(menuMap.hasActivities ? areasMenu : [])
                      .sort((a, b) => a.order - b.order)
                      .map((subMenu, subMenuIndex) => {
                        const hasChildren = subMenu.subItems?.length > 0

                        if (!hasChildren)
                          return (
                            <Link
                              key={subMenu.name}
                              isActive={!ordering}
                              to={`${menuMap.path}/${subMenu.path}`}
                            >
                              <OptionsBase className={'removable-li'}>
                                <div style={{ display: 'flex', alignItems: 'start' }}>
                                  <IconButton
                                    className={subMenu.icon}
                                    pointer
                                    marginRight={10}
                                    marginTop={4}
                                  />
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>
                                      {t(subMenu.translate)}
                                      {subMenu.isNew && (
                                        <span
                                          style={{
                                            backgroundColor: '#faad14',
                                            color: 'white',
                                            padding: '2px 8px',
                                            borderRadius: 4,
                                            marginLeft: 10,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          &nbsp;Novo&nbsp;
                                        </span>
                                      )}
                                      {subMenu.beta && (
                                        <span
                                          style={{
                                            backgroundColor: '#722ed1',
                                            color: 'white',
                                            padding: '2px 8px',
                                            borderRadius: 4,
                                            marginLeft: 10,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          &nbsp;Beta&nbsp;
                                        </span>
                                      )}
                                    </span>
                                    {subMenu.subLabel && (
                                      <span style={{ opacity: 0.7 }}>{subMenu.subLabel}</span>
                                    )}
                                  </div>
                                </div>
                              </OptionsBase>
                            </Link>
                          )

                        return (
                          <>
                            <Link
                              key={`${subMenu.name}-link`}
                              isActive={menuMap.subMenuPath && !ordering}
                              to={`${menuMap.path}/${menuMap.subMenuPath}/${subMenu.id}`}
                            >
                              <OptionsBase
                                key={subMenu.name}
                                header
                                isActive
                                pointer={menuMap.subMenuPath}
                                className={menuMap.subMenuPath && !ordering ? 'removable-li' : ''}
                                justify={ordering && 'space-between'}
                              >
                                {t(subMenu.name)}
                                {ordering && (
                                  <SortButton
                                    sortUp={() => sortArea(subMenu.order, subMenu.order - 1)}
                                    sortDown={() => sortArea(subMenu.order, subMenu.order + 1)}
                                    hiddenUp={subMenuIndex === 0}
                                    hiddenDown={
                                      subMenuIndex ===
                                      menuMap.subMenu.concat(menuMap.hasActivities ? areasMenu : [])
                                        .length -
                                        1
                                    }
                                  />
                                )}
                              </OptionsBase>
                            </Link>
                            {subMenu.subItems
                              .sort((a, b) => a.order - b.order)
                              .map((subItem, subItemIndex) => (
                                <Link
                                  key={subItem.name}
                                  isActive={!ordering}
                                  to={`${menuMap.path}/${subItem.subPath}`}
                                >
                                  <OptionsBase
                                    isChildren
                                    key={subItem.subName}
                                    justify={ordering && 'space-between'}
                                    className={'removable-li'}
                                  >
                                    {t(subItem.subName)}
                                    {ordering && (
                                      <SortButton
                                        sortUp={() =>
                                          sortWorkStation(
                                            subMenu.id,
                                            subItem.order,
                                            subItem.order - 1
                                          )
                                        }
                                        sortDown={() =>
                                          sortWorkStation(
                                            subMenu.id,
                                            subItem.order,
                                            subItem.order + 1
                                          )
                                        }
                                        hiddenUp={subItemIndex === 0}
                                        hiddenDown={subItemIndex === subMenu.subItems.length - 1}
                                      />
                                    )}
                                  </OptionsBase>
                                </Link>
                              ))}
                          </>
                        )
                      })}
                  </OptionsWrapper>
                )}
              </Fragment>
            )
          })}
        {!isMobile && !isAndroid && (
          <OptionsBase
            divisor
            name={'divisor-menu'}
            onMouseEnter={showSubMenu}
            justify={'space-between'}
            onClick={() => {
              setOrdering(!ordering)
              setTimeout(() => setMenuOpen(true), 10)
            }}
          >
            <FlexColumn>{t('sidebar.nav.changeSorting')}</FlexColumn>
            <FlexColumn>
              <BaseCheckBox checked={ordering} marginRight={0.1} />
            </FlexColumn>
          </OptionsBase>
        )}
      </OptionsWrapper>
    </Options>
  )
})

export default withNamespaces()(withRouter(inject('rootStore')(ButtonMenu)))
