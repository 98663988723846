const Menu = [
  {
    name: 'WorkStation',
    path: 'workstation',
    icon: 'fas fa-industry',
    translate: 'sidebar.nav.WorkStation',
    allowedRoles: ['workstation', 'admin', 'superuser'],
    order: 0,
    hasActivities: true,
    subMenuPath: 'overview',
    subMenu: [
      {
        name: 'Overview',
        path: 'overview',
        icon: 'fas fa-grip-horizontal',
        translate: 'sidebar.nav.PLANNING.overview',
      },
    ],
  },
  {
    name: 'Real Time',
    path: 'real-time',
    icon: 'fas fa-desktop',
    translate: 'sidebar.nav.REAL-TIME',
    allowedRoles: ['realtimetracking', 'planning', 'admin', 'superuser'],
    order: 1,
  },
  {
    name: 'Planning',
    path: 'planning',
    icon: 'far fa-calendar-alt',
    translate: 'sidebar.nav.PLANNING.PLANNING',
    allowedRoles: ['planning', 'realtimetracking', 'admin', 'superuser'],
    order: 2,
    hasActivities: true,
    subMenuPath: 'overview',
    subMenu: [
      {
        name: 'Overview',
        path: 'overview',
        icon: 'fas fa-grip-horizontal',
        translate: 'sidebar.nav.PLANNING.overview',
      },
    ],
  },
  {
    name: 'Dashboard',
    path: 'dashboard',
    icon: 'fa fa-chart-line',
    translate: 'sidebar.nav.DASHBOARD.DASHBOARD',
    order: 3,
    subMenu: [
      {
        name: 'Work Order',
        path: 'work-order',
        translate: 'sidebar.nav.DASHBOARD.workOrder',
        allowedRoles: ['realtimetracking', 'planning', 'admin', 'superuser'],
      },
      {
        name: 'Analytics',
        path: 'analytics',
        translate: 'sidebar.nav.DASHBOARD.analytics',
        beta: true,
        allowedRoles: ['realtimetracking', 'planning', 'admin', 'superuser'],
      },
    ],
  },
  {
    name: 'Task Scheduler',
    path: 'tasks',
    icon: 'fas fa-tasks',
    translate: 'sidebar.nav.tasks.TASKS',
    order: 4,
    subMenu: [
      {
        name: 'Quality Inspection',
        path: 'quality-inspection',
        icon: 'fa fa-flask',
        translate: 'sidebar.nav.tasks.qualityInspection',
        allowedRoles: ['workstation', 'admin', 'superuser'],
        isNew: true,
        subLabel: 'Gerencie suas inspeções de qualidade',
      },
      {
        name: 'Materials Supply',
        path: 'materials-supply',
        icon: 'fa fa-dolly',
        translate: 'sidebar.nav.tasks.materialsSupply',
        allowedRoles: ['workstation', 'admin', 'superuser'],
        subLabel: 'Organize o abastecimento de materiais',
      },
    ],
  },
  {
    name: 'Setup',
    path: 'setup',
    icon: 'fas fa-cogs',
    translate: 'sidebar.nav.SETUP.SETUP',
    order: 5,
    allowedRoles: ['manufacturing_eng', 'admin', 'superuser'],
    subMenu: [
      {
        name: 'SKUs',
        path: 'production',
        icon: 'fas fa-barcode',
        translate: 'sidebar.nav.SETUP.production',
        subLabel: 'Gerencie seus produtos',
      },
      {
        name: 'Production Inspection',
        path: 'production-inspection',
        icon: 'fas fa-wrench',
        isNew: true,
        translate: 'sidebar.nav.SETUP.productionInspection',
        subLabel: 'Crie tipos de manutenções, inspeções e testes',
      },
      {
        name: 'Processos e Sequências',
        path: 'processExecution',
        icon: 'fas fa-clipboard-check',
        translate: 'sidebar.nav.SETUP.processExecution',
        subLabel: 'Crie formulários de execução',
      },
      {
        name: 'Shifts',
        path: 'shift',
        icon: 'far fa-clock',
        translate: 'sidebar.nav.SETUP.shift',
        subLabel: 'Gerencie seus turnos',
      },
      {
        name: 'Centers of Work',
        path: 'area',
        icon: 'fas fa-map-marker',
        translate: 'sidebar.nav.SETUP.area',
        subLabel: 'Gerencie seus centros de custo',
      },
      {
        name: 'workstations',
        path: 'workstation',
        icon: 'fas fa-people-carry',
        translate: 'sidebar.nav.SETUP.workStation',
        subLabel: 'Gerencie suas estações de trabalho',
      },
      {
        name: 'Employees',
        path: 'employee',
        icon: 'far fa-id-card',
        translate: 'sidebar.nav.SETUP.employee',
        subLabel: 'Gerencie seus colaboradores',
      },
      {
        name: 'Pausas',
        path: 'pauses',
        icon: 'fas fa-pause',
        translate: 'sidebar.nav.SETUP.pauses',
        subLabel: 'Crie pausas específicas para sua produção',
      },
      {
        name: 'Etiquetas',
        path: 'tags',
        icon: 'fas fa-print',
        translate: 'sidebar.nav.SETUP.print',
        subLabel: 'Crie etiquetas com sua marca',
      },
    ],
  },
  {
    name: 'Admin',
    path: 'admin',
    icon: 'fas fa-users-cog',
    translate: 'sidebar.nav.ADMIN.ADMIN',
    allowedRoles: ['superuser'],
    order: 6,
    subMenu: [
      {
        name: 'Users',
        path: 'user',
        icon: 'fas fa-users',
        translate: 'sidebar.nav.ADMIN.user',
      },
    ],
  },
]

export default Menu
