import Socket from './socket'
import { reaction } from 'mobx'

class WorkStationHub extends Socket {
  constructor(rootStore) {
    super('workStation', rootStore)
    this.rootStore = rootStore

    reaction(
      () => this.connection,
      async () => {
        console.info('came to reaction')
      }
    )

    reaction(
      () => this.isConnected,
      async () => {
        console.info('came to reaction: isConnected')
      }
    )

    reaction(
      () => this.isReconnecting,
      async () => {
        console.info('came to reaction: isReconnecting')
      }
    )
  }

  configureHub() {
    super.configureHub()
    this.connection.on('EmployeeLoggedOut', this.employeeLoggedOutHandler || (() => {}))
    this.connection.on('EmployeeLoggedIn', this.employeeLoggedInHandler || (() => {}))
    this.connection.on('EmployeeChangedStatus', this.employeeChangedStatusHandler || (() => {}))
    this.connection.on('ProductionAlertOpened', this.productionAlertOpenedHandler || (() => {}))
    this.connection.on(
      'ProductionAlertAcknowledged',
      this.productionAlertAcknowledgedHandler || (() => {})
    )
    this.connection.on('ProductionAlertClosed', this.productionAlertClosedHandler || (() => {}))
    this.connection.on('WorkStationOrderStarted', this.workStationOrderStartedHandler || (() => {}))
    this.connection.on('WorkStationOrderPaused', this.workStationOrderPausedHandler || (() => {}))
    this.connection.on('WorkStationOrderResumed', this.workStationOrderResumedHandler || (() => {}))
    this.connection.on(
      'WorkStationOrderFinished',
      this.workStationOrderFinishedHandler || (() => {})
    )
    this.connection.on('RealLaborTimeUpdated', this.realLaborTimeUpdatedHandler || (() => {}))
    this.connection.on(
      'ProducedQuantitiesUpdated',
      this.producedQuantitiesUpdatedHandler || (() => {})
    )
    this.connection.on('ScheduleUpdated', this.scheduleUpdatedHandler || (() => {}))
    this.connection.on('WorkOrderChanged', this.workOrderChangedHandler || (() => {}))
    this.connection.on('WorkOrderReset', this.workOrderResetHandler || (() => {}))
    this.connection.on('MachineIntegrationUpdated', this.machineIntegrationUpdated || (() => {}))

    this.connection.on(
      'WorkOrderNoteUpdated',
      this.workOrderNoteUpdateHandler ||
        ((e) => {
          console.debug('Work order note updated', e)
        })
    )

    this.connection.on(
      'ProcessUpdatedOnWorkStation',
      this.processUpdatedHandler ||
        ((e) => {
          console.debug('Process updated', e)
        })
    )

    this.connection.on(
      'ProcessExecutionUpdated',
      this.processExecutionUpdated ||
        ((e) => {
          console.debug('Process updated', e)
        })
    )

    this.connection.on('WorkStationPaused', this.workStationPausedHandler || (() => {}))
    this.connection.on('WorkStationResumed', this.workStationResumedHandler || (() => {}))
    // this.connection.on('ProcessExecutionUpdated', this.processExecutionUpdatedHandler || (() => {}))
  }

  joinWorkStation = async (workStationId) =>
    this.connection && (await this.connection.invoke('joinWorkStation', workStationId))

  joinGeneral = async () => this.connection && (await this.connection.invoke('JoinGeneral'))

  loginOrLogoutEmployee = async (employeeId) =>
    await this.connection.invoke('LoginOrLogoutEmployee', employeeId)

  acknowledgeProductionAlert = async (id, acknowledgedEmployeeId) =>
    await this.connection.invoke('AcknowledgeProductionAlert', id)

  closeProductionAlert = async (id, closedEmployeeId, alertReason) => {
    await this.connection.invoke('CloseProductionAlert', id, alertReason || 'Não informado')
  }

  startWorkStationOrder = async (id, type, beginEmployeeId) =>
    await this.connection.invoke('StartWorkStationOrder', {
      id,
      type,
      beginEmployeeId,
    })

  resumeWorkStationOrder = async (id, type, resumeEmployeeId) =>
    await this.connection.invoke('ResumeWorkStationOrder', {
      id,
      type,
      resumeEmployeeId,
    })

  changeCurrentWorkOrder = async ({
    currentWorkOrderId,
    workOrderId,
    assignedWorkStationId,
    note,
    loggedEmployeeIds,
    isSetup,
  }) =>
    await this.connection.invoke('ChangeCurrentWorkOrder', {
      currentWorkOrderId,
      workOrderId,
      assignedWorkStationId,
      note,
      loggedEmployeeIds,
      isSetup,
    })

  pauseWorkStationOrder = async (id, type, pauseEmployeeId, currentStatusId, note, parentId) =>
    await this.connection.invoke('PauseWorkStationOrder', {
      id,
      type,
      pauseEmployeeId,
      currentStatusId,
      note,
      parentStatusId: parentId,
    })

  pauseWorkStation = async (id, type, pauseEmployeeId, currentStatusId, note, workStationId) =>
    await this.connection.invoke('PauseWorkStation', {
      id,
      type,
      pauseEmployeeId,
      currentStatusId,
      note,
      workStationId,
    })

  resumeWorkStation = async (id, type, pauseEmployeeId, currentStatusId, note, workStationId) =>
    await this.connection.invoke('ResumeWorkStation', {
      id,
      type,
      pauseEmployeeId,
      currentStatusId,
      note,
      workStationId,
    })

  finishWorkStationOrder = async (id, type, endEmployeeId, producedQuantity, defectiveQuantity) =>
    await this.connection.invoke('FinishWorkStationOrder', {
      id,
      type,
      endEmployeeId,
      producedQuantity,
      defectiveQuantity,
    })

  updateProducedQuantities = async (workOrderId, producedQuantity, defectiveQuantity) => {
    await this.connection.invoke('UpdateProducedQuantities', {
      workOrderId,
      producedQuantity,
      defectiveQuantity,
    })
  }

  openProductionAlert = async (openedEmployeeId, typeId, workOrderId = null) =>
    await this.connection.invoke(
      'OpenProductionAlert',
      openedEmployeeId ? openedEmployeeId : '',
      typeId,
      workOrderId ?? 0
    )

  setEmployeeStoppage = async (employeeId, stoppageId, note) =>
    await this.connection.invoke('SetEmployeeStoppage', employeeId, stoppageId, note)

  clearEmployeeStoppage = async (employeeId) =>
    await this.connection.invoke('ClearEmployeeStoppage', employeeId)

  resetWorkOrder = async (workOrderId) => {
    await this.connection.invoke('ResetWorkOrder', workOrderId)
  }

  schedule = async (ordersSchedule) => {
    await this.connection.invoke('Schedule', ordersSchedule)
  }

  shiftAndSchedule = async (ordersSchedule) => {
    await this.connection.invoke('ShiftAndSchedule', ordersSchedule)
  }

  validateConnection = async () => {
    if (!this.isConnected) await this.connect()
  }

  /* Override functions */
  employeeLoggedOutHandler
  onEmployeeLoggedOut = (handler) => (this.employeeLoggedOutHandler = handler)

  employeeLoggedInHandler
  onEmployeeLoggedIn = (handler) => (this.employeeLoggedInHandler = handler)

  employeeChangedStatusHandler
  onEmployeeChangedStatus = (handler) => (this.employeeChangedStatusHandler = handler)

  productionAlertOpenedHandler
  onProductionAlertOpened = (handler) => (this.productionAlertOpenedHandler = handler)

  productionAlertAcknowledgedHandler
  onProductionAlertAcknowledged = (handler) => (this.productionAlertAcknowledgedHandler = handler)

  productionAlertClosedHandler
  onProductionAlertClosed = (handler) => (this.productionAlertClosedHandler = handler)

  workStationOrderStartedHandler
  onWorkStationOrderStarted = (handler) => (this.workStationOrderStartedHandler = handler)

  workStationOrderPausedHandler
  onWorkStationOrderPaused = (handler) => (this.workStationOrderPausedHandler = handler)

  workStationOrderResumedHandler
  onWorkStationOrderResumed = (handler) => (this.workStationOrderResumedHandler = handler)

  workStationOrderFinishedHandler
  onWorkStationOrderFinished = (handler) => (this.workStationOrderFinishedHandler = handler)

  realLaborTimeUpdatedHandler
  onRealLaborTimeUpdated = (handler) => (this.realLaborTimeUpdatedHandler = handler)

  producedQuantitiesUpdatedHandler
  onProducedQuantitiesUpdated = (handler) => (this.producedQuantitiesUpdatedHandler = handler)

  scheduleUpdatedHandler
  onScheduleUpdated = (handler) => (this.scheduleUpdatedHandler = handler)

  workOrderChangedHandler
  onWorkOrderChanged = (handler) => (this.workOrderChangedHandler = handler)

  workOrderResetHandler
  onWorkOrderReset = (handler) => (this.workOrderResetHandler = handler)

  machineIntegrationUpdated
  onMachineIntegrationUpdated = (handler) => (this.machineIntegrationUpdated = handler)

  processExecutionUpdated
  onProcessExecutionUpdated = (handler) => (this.processExecutionUpdated = handler)

  workOrderNoteUpdateHandler
  onWorkOrderNoteUpdate = (handler) => (this.workOrderNoteUpdateHandler = handler)

  processUpdatedHandler
  onProcessUpdate = (handler) => (this.processUpdatedHandler = handler)

  workStationPausedHandler
  onWorkStationPaused = (handler) => (this.workStationPausedHandler = handler)

  workStationResumedHandler
  onWorkStationResumed = (handler) => (this.workStationResumedHandler = handler)

  // processExecutionUpdatedHandler
  // onProcessExecutionUpdated = (handler) => (this.processExecutionUpdatedHandler = handler)
}

export default WorkStationHub
